.branchName {
  font-size: 16px !important;
  padding-top: 5px;
  overflow: hidden !important;
  width: 90vw;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  height: 25px;
  margin-left: 15px;
}

.branchSubtitle {
  font-size: 14px !important;
  padding-top: 5px;
  overflow: hidden !important;
  width: 90vw;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  height: 25px;
  margin-left: 15px;
}

.header {
  background: #fafbfd;
  padding-top: 20px;
  height: 90px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0px 0px 10px 10px;
  width: 100%;
}

.subHeader {
  margin-top: -65px !important;
}

.search {
  color: #c0c0c0;
  height: 4vh;
  margin-left: 3vw;
  border-radius: 100px !important;
  width: 105%;
}

.searchIcon {
  color: #c0c0c0;
}

.hamburguer {
  margin-left: -5vw;
}

.filterText {
  width: auto;
  width: 100px;
  max-width: 100px;
}

.textField {
  padding-right: 15px;
}

.filterButton {
  width: 40px;
}

.filter {
  color: #c0c0c0 !important;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(153, 153, 153, 0.15) !important;
  border-radius: 100px !important;
  text-transform: capitalize !important;
  padding-right: 15px;
  width: 100px;
  min-width: 100px;
  max-width: 200px;
}

.filterIcon {
  max-width: 12px;
  display: inline-flex;
}

.restaurantLogo {
  height: 70px;
  width: 70px;
  border-radius: 50px;
  border: 2px solid white;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1) !important;
  margin-left: 5vw;
}

.chip {
  background: linear-gradient(163deg, #e20613 -51.13%, #ee7b5a 89.47%);
  border-radius: 60px !important;
  color: white !important;
  font-size: 10px !important;
  height: 18px !important;
}

.menu3d {
  background: linear-gradient(163deg, #e20613 -51.13%, #ee7b5a 89.47%);
  border-radius: 60px !important;
  font-size: 10px !important;
  height: 4vh !important;
  margin-top: -30px !important;
  max-width: 200px;
  text-transform: uppercase;
}

.infoContainer {
  margin-bottom: -10px !important;
}

.info {
  font-size: 10px !important;
}

@media (min-width: 1300px) and (orientation: landscape) {
  .info-label{
    margin-left: 200px !important;
  }
  .info-arrow{
    margin-left: -100px;
    margin-top: 10px;
  }
  .branchName, .branchSubtitle {
    height: 40px;
  }
}

@media (max-width: 700px) and (orientation: landscape) {
  .header {
    height: 60px;
  }

  .restaurantLogo,
  .branchName,
  .branchSubtitle {
    display: none;
  }

  .filter {
    width: 300px !important;
    height: 8vh;
    margin-top: -22px !important;
  }

  .countryFlag {
    margin-top: -20px !important;
  }

  .search {
    color: #c0c0c0;
    height: 8vh;
    margin-left: 3vw;
    border-radius: 100px !important;
    width: 120%;
    margin-top: -15px;
  }

  .menu3d {
    height: 20px !important;
    margin-top: 15px !important;
    z-index: 10000;
  }

  .info {
    font-size: 1em !important;
  }
}

@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) {
  .chip {
    background: linear-gradient(163deg, #e20613 -51.13%, #ee7b5a 89.47%);
    border-radius: 60px !important;
    color: white !important;
    height: 18px !important;
  }

  .menu3d {
    background: linear-gradient(163deg, #e20613 -51.13%, #ee7b5a 89.47%);
    border-radius: 60px !important;
    font-size: 15px !important;
    height: 8vh !important;
    margin-top: -15% !important;
    max-width: 200px;
    text-transform: uppercase;
  }

  .infoContainer {
    margin-bottom: -10px !important;
  }

  .info {
    color: #c0c0c0 !important;
    font-size: 10px !important;
    display: none;
  }

  .search {
    color: #c0c0c0;
    height: 4vh;
    margin-left: 3vw;
    border-radius: 100px !important;
    width: 120%;
  }

  .restaurantLogo {
    height: 80px;
    width: 80px;
    border-radius: 46px;
    border: 5px solid white;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1) !important;
    margin-left: 5vw;
  }

  .filterButton {
    width: 40px;
    padding-left: 28px;
  }

  .filterIcon {
    max-width: 12px;
    display: inline-flex;
  }

  .filter {
    max-width: 12px;
    margin-left: 15vw;
  }

  .hamburguer {
    margin-left: 15vw;
    width: 20vh;
  }

  .branchName {
    font-size: 3vw !important;
    margin-left: -45%;
    padding-top: 5px;
    left: 0;
  }
}

@media (min-width: 740px) and (max-width: 1366px) and (max-height: 400px) and (orientation: landscape) {
  .restaurantLogo,
  .branchName,
  .branchSubtitle {
    display: none;
  }

  .menu {
    font-size: 30px;
  }

  .menu3d {
    font-size: 15px !important;
    height: 25px !important;
    margin-top: 10px !important;
    z-index: 100000;
  }

  .info {
    font-size: 1em !important;
  }

  .search {
    color: #c0c0c0;
    height: 38px;
    margin-left: 1vw;
    border-radius: 100px !important;
    width: 130%;
  }

  .filter {
    width: 30vw !important;
    margin-left: 18vw;
  }

  .hamburguer {
    margin-left: 15vw;
    width: 20vh;
  }

  .branchName {
    font-size: 3vw !important;
    margin-left: -50%;
    padding-top: 5px;
    left: 0;
  }
}

@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) {
  .menu {
    font-size: 30px;
  }

  .chip {
    background: linear-gradient(163deg, #e20613 -51.13%, #ee7b5a 89.47%);
    border-radius: 60px !important;
    color: white !important;
    height: 18px !important;
  }

  .menu3d {
    background: linear-gradient(163deg, #e20613 -51.13%, #ee7b5a 89.47%);
    border-radius: 60px !important;
    font-size: 15px !important;
    height: 30px !important;
    margin-top: -15% !important;
    max-width: 200px;
    text-transform: uppercase;
  }

  .infoContainer {
    margin-bottom: -10px !important;
  }

  .info {
    color: #c0c0c0 !important;
    font-size: 10px !important;
    display: none;
  }

  .search {
    color: #c0c0c0;
    height: 30px;
    margin-left: 3vw;
    border-radius: 100px !important;
    width: 120%;
  }

  .restaurantLogo {
    height: 80px;
    width: 80px;
    border-radius: 46px;
    border: 5px solid white;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1) !important;
    margin-left: 5vw;
  }

  .filterButton {
    width: 40px;
    padding-left: 28px;
  }

  .filterIcon {
    max-width: 12px;
    display: inline-flex;
  }

  .filter {
    max-width: 12px;
    margin-left: 15vw;
  }

  .hamburguer {
    margin-left: 15vw;
    width: 20vh;
  }

  .branchName {
    font-size: 25px !important;
    margin-left: -100px;
    padding-top: 5px;
    left: 0;
  }
}

@media (min-width: 1024px) and (orientation: landscape) {
  .info {
    font-size: 25px !important;
  }

  .branchName {
    font-size: 25px !important;
    margin-left: -25vh;
    padding-top: 5px;
    left: 0;
  }

  .search {
    height: 40px;
    border-radius: 100px !important;
    width: 135%;
  }

  .filterButton {
    width: 40px;
    padding-left: 28px;
  }

  .filterIcon {
    max-width: 12px;
    display: inline-flex;
  }

  .filter {
    width: 200px;
    margin-left: 50px !important;
  }

  .restaurantLogo {
    height: 70px;
    width: 70px;
    border-radius: 55px;
    border: 5px solid white;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1) !important;
    margin-left: 5vw;
  }

  .hamburguer {
    margin-left: 15vw;
    width: 20vh;
  }

  .menu3d {
    background: linear-gradient(163deg, #e20613 -51.13%, #ee7b5a 89.47%);
    border-radius: 60px !important;
    font-size: 15px !important;
    height: 35px !important;
    margin-top: -15% !important;
    max-width: 200px;
    text-transform: uppercase;
  }
}

@media (min-width: 1200px) and (orientation: landscape) {
  .branchName {
    font-size: 25px !important;
    margin-left: -280px;
    padding-top: 5px;
    left: 0;
  }

  .restaurantLogo {
    height: 100px;
    width: 100px;
    border-radius: 55px;
    border: 5px solid white;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1) !important;
    margin-left: 5vw;
  }

  .menu {
    font-size: 30px;
  }
}

@media (min-width: 1024px) and (orientation: portrait) {
  .menu3d {
    background: linear-gradient(163deg, #e20613 -51.13%, #ee7b5a 89.47%);
    border-radius: 60px !important;
    font-size: 15px !important;
    height: 35px !important;
    margin-top: -10% !important;
    max-width: 200px;
    text-transform: uppercase;
  }

  .branchName {
    font-size: 25px !important;
    margin-left: -200px;
    padding-top: 5px;
    left: 0;
  }

  .filterButton {
    width: 40px;
    padding-left: 28px;
  }

  .filterIcon {
    max-width: 20px;
    display: inline-flex;
  }

  .filter {
    max-width: 20px;
    margin-left: 20vw;
  }

  .search {
    height: 40px;
    border-radius: 100px !important;
    width: 130%;
  }

  .menu {
    font-size: 30px;
  }
}
