.smv-button-fullscreen {
  display: none !important;
}

.smv-model-ar-button, .slot.poster {
  display: none !important;
}

.smv-loader.model-loader.smv-progress-loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
