.show-header {
  background-size: cover;
  background-repeat: round;
}

.show-ul {
  overflow-y: auto;
}

.show-ul-inside {
  height: 80vh;
}

.show.show-header-back-arrow {
  font-size: 20;
  margin-top: 30px;
}

.show-header-container-chip {
  margin-bottom: 40px;
  position: relative;
  z-index: 1000000;
}

.show-header-chip3d {
  height: 4.5vh !important;
  border-radius: 50px !important;
  font-size: 10px !important;
  border: 1px solid transparent !important;
  bottom: 0;
  left: 0;
  position: relative;
}

@media (max-width: 920px) and (orientation: landscape) {
  .show-header-chip3d {
    height: 8vh !important;
    border-radius: 50px !important;
    font-size: 15px !important;
    border: 1px solid transparent !important;
    bottom: 0;
    left: 0;
    position: relative;
  }
}

@media (max-width: 300px) and (max-height: 700px) and (orientation: portrait) {
  .show-ul {
    height: 65vh !important;
    margin-top: -35px !important;
  }

  .show-header-chip3d {
    height: 3vh !important;
    margin-bottom: -25px !important;
    font-size: 16px !important;
  }
}



@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) {
  .show-header-chip3d {
    height: 3vh !important;
    font-size: 16px !important;
  }
}
@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape) {
  .show-header-chip3d {
    height: 8vh !important;
    font-size: 16px !important;
  }
}

@media (min-height: 800px) and (max-height: 900px) and (orientation: portrait) {
  .show-header-chip3d {
    margin-bottom: -5px !important;
    font-size: 16px !important;
  }
}

@media (min-height: 900px) and (max-height: 1000px) and (orientation: portrait) {
  .show-header-chip3d {
    margin-bottom: -10px !important;
    font-size: 16px !important;
  }
}

@media (min-height: 1000px) and (orientation: portrait) {
  .show-header {
    height: 50vh;
  }

  .show-header-chip3d {
    margin-bottom: 0px !important;
    font-size: 16px !important;
  }
}

@media (min-width: 900px) and (min-height: 1000px) and (orientation: portrait) {
  .show-ul {
    height: 60vh !important;
    & p {
      font-size: 30px !important;
    }
  }

  .show-header-chip3d {
    margin-bottom: -5px !important;
    font-size: 30px !important;
  }
}

@media (min-width: 1000px) and (min-height: 1300px) and (orientation: portrait) {
  .show-header, .show-ul {
    width: 98vw !important;
  }
  
  .show-ul {
    height: 60vh !important;
    & p {
      font-size: 50px !important;
    }
  }

  .show-header-chip3d {
    margin-bottom: -5px !important;
  }
}

@media (min-width: 400px) and (max-width: 500px) and (min-height: 900px) and (orientation: portrait) {
  .show-header {
    height: 45vh !important;
  }

  .show-ul {
    height: 65vh !important;
    margin-top: -35px !important;
  }

  .show-header-chip3d {
    height: 3vh !important;
    margin-bottom: -25px !important;
    font-size: 16px !important;
  }
}

@media (min-width: 400px) and (max-width: 500px) and (min-height: 800px) and (max-height: 900px) and (orientation: portrait) {
  .show-header {
    height: 50vh;
  }

  .show-ul {
    height: 65vh !important;
    margin-top: -35px !important;
  }

  .show-header-chip3d {
    height: 3vh !important;
    margin-bottom: -25px !important;
    font-size: 16px !important;
  }
}

@media (min-width: 500px) and (max-width: 600px) and (min-height: 700px) and (max-height: 800px) and (orientation: portrait) {
  .show-header {
    height: 50vh !important;
  }

  .show-ul {
    height: 65vh !important;
    margin-top: -35px !important;
  }

  .show-header-chip3d {
    height: 3vh !important;
    margin-bottom: -25px !important;
    font-size: 16px !important;
  }
}
