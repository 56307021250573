.viewer-sirv {
  margin-top: 100px !important;
  z-index: 10;
}
.viewer-actions {
  padding-left: -40px;
  width: 100%;
  position: absolute;
}

.viewer-dish-image {
  height: 10vh;
  width: 20vw;
  border-radius: 10px;
}
.viewer-scroll-product-title {
  max-width: 100% !important;
}
.viewer-product-title {
  width: 20vw;
  font-size: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
  white-space: break-spaces !important;
  text-align: center;
}

.viewer-dish-margin {
  width: 25vw;
  margin-left: 5vw;
  height: 150vh;
  padding: 30px;
  z-index: 2;
}

.viewer-dish-root {
  height: auto;
  width: max-content !important;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
}

.viewer-panel-root {
  height: 15vh;
  overflow-y: hidden !important;
  overflow-x: auto;
  margin-left: -6vw;
  position: relative;
  z-index: 0;
}

@media (max-width: 1024px) and (orientation: landscape) {
  .viewer-panel-root {
    height: 80vh !important;
    overflow-y: auto;
    position: relative;
    width: 20%;
    margin-left: 30px;
  }

  .viewer-dish-root {
    height: auto;
    width: max-content;
    white-space: nowrap;
  }

  .viewer-dish-margin {
    height: 150px;
  }
}

@media (max-width: 915px) and (orientation: landscape) {
  .viewer-dish-margin {
    width: 12vw;
    margin-left: 145px;
    padding: 100px;
    height: 70px;
    margin-bottom: 15vh;
  }
  .viewer-dish-image {
    height: 8vw;
    width: 10vw;
    border-radius: 10px;
  }
  .viewer-product-title {
    width: 10vw;
    font-size: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: bold;
    white-space: break-spaces !important;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1366px) and (orientation: landscape) {
  .viewer-dish-image {
    height: 90px;
    width: 100px;
    border-radius: 10px;
  }
  .viewer-product-title {
    width: 100px;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: bold;
    white-space: break-spaces !important;
    text-align: center;
  }
  .viewer-dish-margin {
    width: 14vw;
  }
}
@media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) {
  .viewer-dish-image {
    height: 90px;
    width: 100px;
    border-radius: 10px;
  }
  .viewer-product-title {
    width: 10vw;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: bold;
    white-space: break-spaces !important;
    text-align: center;
  }
  .viewer-dish-margin {
    width: 15vw;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .viewer-dish-image {
    height: 90px;
    width: 100px;
    border-radius: 10px;
  }
  .viewer-product-title {
    width: 90%;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: bold;
    white-space: break-spaces !important;
    text-align: center;
  }
  .viewer-dish-margin {
    width: 15vw;
  }
}
@media (min-width: 768px) and (max-width: 1190px) and (orientation: landscape) {
  .viewer-dish-image {
    height: 90px;
    width: 100px;
    border-radius: 10px;
  }
  .viewer-product-title {
    width: 90%;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: bold;
    white-space: break-spaces !important;
    text-align: center;
  }
  .viewer-dish-margin {
    width: 15vw;
  }
}
@media (min-width: 1025px) and (max-width: 1366px) and (orientation: landscape) {
  .viewer-dish-image {
    height: 100px;
    width: 110px;
    border-radius: 10px;
  }
  .viewer-product-title {
    width: 100%;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: bold;
    white-space: break-spaces !important;
    text-align: center;
  }
  .viewer-dish-margin {
    width: 12vw;
  }
}

@media (max-width: 915px) and (orientation: landscape) {
  .viewer-dish-root {
    height: max-content;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 200px;
    margin-left: 35px !important;
  }
}
