.parent-title {
  font-weight: bold;
}
.parent-subTitle {
  color: #666666;
  font-size: 10;
}
.parent-chip {
  height: 20px !important;
  border-radius: 50px !important;
  margin: 5px !important;
  font-size: 10px !important;
  color: white !important;
  background: linear-gradient(
    163deg,
    #e20613 -51.13%,
    #ee7b5a 89.47%
  ) !important;
  border: 1px solid transparent !important;
}
