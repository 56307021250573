.sidebarItem {
  width: 100vw;
}

.sidebarText {
  color: white;
  font-weight: bold !important;
}
.sidebarLanguageText {
  color: black;
  font-weight: bold !important;
}
